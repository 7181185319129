import './loginform.css';

import md5 from 'md5';
import { checkUserRegistration } from '../../scripts/checkuser.js';
import { loaderOn, loaderOff } from '../../components/loadercontrol.js';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';


const LoginForm = (props) => {

    const [autorized, setAutorized] = useState(false);

    async function checkUserProc(e) {

        e.preventDefault();

        loaderOn();

        const data = Object.fromEntries(new FormData(e.target));

        props.sessioninfo.loginName = data.loginname;
        props.sessioninfo.loginPass = data.loginpass;
        props.sessioninfo.loginRemember = data.remembermy === 'on' ? true : false;
        props.sessioninfo.userAutorised = false;

        const res = await checkUserRegistration(props.sessioninfo.loginName, md5(props.sessioninfo.loginPass));

        loaderOff();

        if (res.logname === props.sessioninfo.loginName) {
            props.sessioninfo.loginPass = '';
            props.sessioninfo.userAutorised = true;
            if (props.sessioninfo.loginRemember) {
                props.sessioninfo.storage.setItem('loginName', props.sessioninfo.loginName);
                props.sessioninfo.storage.setItem('loginDate', Date.now());
                props.sessioninfo.storage.setItem('loginRemember', props.sessioninfo.loginRemember);

            } else {
                props.sessioninfo.storage.setItem('loginName', '');
                props.sessioninfo.storage.setItem('loginDate', '');
                props.sessioninfo.storage.setItem('loginRemember', false);
            }
        } else {
            props.sessioninfo.storage.setItem('loginName', '');
            props.sessioninfo.storage.setItem('loginDate', '');
            props.sessioninfo.storage.setItem('loginRemember', false);
        }

        setAutorized(props.sessioninfo.userAutorised);
    }

    if (autorized) {
        return (
            <Navigate to='/' />
        )
    }

    return (
        <form className="loginpageform" onSubmit={checkUserProc}>
            <div className="loginpageloginbox">
                <fieldset className="loginpagefieldset">
                    <label className="loginpageloginmargin lightgray" htmlFor="loginname">login</label>
                    <br />
                    <input className="loginpageinputlogin  darkblue lightgray"
                        type="text"
                        id="loginname"
                        name="loginname"
                        required
                        defaultValue={props.sessioninfo.loginName}
                    />
                </fieldset >
                <hr className="loginpageloginhr" />
                <fieldset className="loginpagefieldset">
                    <label className="lightgray" htmlFor="loginpass">password</label>
                    <br />
                    <input className="loginpageinputlogin darkblue lightgray"
                        type="password"
                        id="loginpass"
                        name="loginpass"
                        required
                        defaultValue={props.sessioninfo.loginPass}
                    />
                </fieldset>
                <br />
                <button className="button loginpagesubmitmargin loginpageloginsubmitbutton blue lightgray" type="submit">Sign in</button>
                <fieldset className="loginpagefieldset">
                    <div className="loginpageloginmargin darkgray">
                        <input type="checkbox" id="rememdermy" name="remembermy" defaultChecked={props.sessioninfo.loginRemember} />
                        Remember my ID
                    </div>
                </fieldset>
                <hr className="loginpagesubmitmargin1 loginpageloginhr darkgray" />
            </div>
        </form>
    )
}

export default LoginForm;