
import { Navigate } from "react-router-dom";

const Logout = (props) => {
    props.sessioninfo.userAutorised = false;
    return (
    <Navigate to='/login'/>

    )
}

export default Logout;