import { NavLink } from 'react-router-dom';
import "./navbarmenu.css";

const NavBarMenu = (props) => {

  return (
    <>
      <nav className="navbar navbar-expand bg-dark navbar-dark">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {props.menuItems.filter(el => el.parent === "0").map((el) => (
              (el.folder === "1")
                ? <li key={el.id} className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {el.descr}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {props.menuItems.filter(el1 => el1.parent === el.id).map(el1 => (
                      el1.separat === "1"
                        ? <li key={el1.id}><hr className="dropdown-divider " /></li>
                        : <li key={el1.id} className="dropdown-item ">
                          <NavLink to={el1.link} className="dropdown-link dropdown-item">
                            {el1.descr}
                          </NavLink>
                        </li>
                    ))}
                  </ul>
                </li>
                : <li key={el.id} className="nav-item">
                  <NavLink to={el.link} className="nav-link">
                    {el.descr}
                  </NavLink>
                </li>
            ))}
          </ul>
        </div>
        <form className="d-flex" role="search">
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button className="btn btn-outline-success my-search-button" type="submit">
            Search
          </button>
        </form>
      </nav>
    </>
  )
}

export default NavBarMenu;