import './loginmain.css';

const LoginMain = () => {
    return (
        <div className="loginpagemaininfo">
            <h2 className="loginpageinfotext loginpageinfotextheight">Task Manager</h2>
            <div className="loginpageinfotext1 loginpageinfotextheight1">
                Task Manager
            </div>
        </div>
    )
}

export default LoginMain;