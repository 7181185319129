import NavBarMenu from './navbarmenu.js';
import ToolBar1 from './toolbar1.js';
import ToolBar2 from './toolvar2.js';
import AllFooter from '../allfooter.js';
import './basepage.css';
import { loaderOn, loaderOff } from '../../components/loadercontrol.js';
import { useState, useEffect } from 'react';
import Menu from '../../scripts/menu.js';
import BaseRouter from '../../components/baserouter.js'
import { Navigate } from 'react-router-dom';

const BasePage = (props) => {

  const [menuItems, setMenuItems] = useState([]);

  const getMenuItems = async () => {

    loaderOn();

    const menu = new Menu();

    const mi = await menu.getMenuStructure();

    loaderOff();

    setMenuItems(Array.from(mi));

    props.sessioninfo.menuItems = Array.from(mi);
    if (mi.length > 0) {
      props.sessioninfo.menuLoaded = true;
    }
  }

  useEffect(() => {
    if (!props.sessioninfo.menuLoaded) {
      getMenuItems();
    } else {
      setMenuItems(Array.from(props.sessioninfo.menuItems));
    }
  }, []
  );

  if (!props.sessioninfo.userAutorised) {
    return <Navigate to='/login' replace />
  }

  return (
    <div>
      <NavBarMenu menuItems={menuItems} />
      <div className="basepagework">
        <ToolBar1 />
        <main className="col-10 basepagewindow" id="basepagewindow">
          <BaseRouter sessioninfo={props.sessioninfo} />
        </main>
        <ToolBar2 />
      </div>
      <AllFooter />
    </div>
  )
}

export default BasePage;