import './signup.css';

import md5 from 'md5';
import { isUserPresent, setUserInfo } from '../../scripts/checkuser.js';
import { loaderOn, loaderOff } from '../../components/loadercontrol.js';

const SignUP = (props) => {

    async function sendNewUser(e) {

        e.preventDefault();

        loaderOn();

        const data = Object.fromEntries(new FormData(e.target));

        const res = await isUserPresent(data.newuser_loginname, data.newuser_email, data.newuser_phone);

        let result = 'signin';

        switch (res.subject) {
            case "login":
                result = prompt('LoginName already present. Try again?', '');
                break;
            case "email":
                result = prompt('This email already present. Try again?', '');
                break;
            case "phone":
                result = prompt('This phone already present. Try again?', '');
                break;
            default:
                data.newuserloginpass = md5(data.newuser_loginpass);
                await setUserInfo(data);
                props.sessioninfo.loginName = data.newuser_loginname;
                props.sessioninfo.loginPass = '';
                props.sessioninfo.loginRemember = false;
                break;
        }

        loaderOff();

        if (result === 'signin') {
            props.setPage('signin');
        }
    }

    return (
        <div className="loginpagemaininfo">
            <form onSubmit={sendNewUser}>
                <label htmlFor="newuser_loginname">Login Name</label>
                <input id="newuser_loginname" name="newuser_loginname" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_name">User Name</label>
                <input id="newuser_name" name="newuser_name" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_email">User email</label>
                <input id="newuser_email" name="newuser_email" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_phone">User phone</label>
                <input id="newuser_phone" name="newuser_phone" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_loginpass">User password</label>
                <input id="newuser_loginpass" name="newuser_loginpass" type="password" defaultValue=''></input>
                <br />
                <button type="submit">Register</button>
            </form>
        </div>
    )
}

export default SignUP;