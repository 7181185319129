import './logobox.css';
import Logo_Signin from "../../img/logo_signin.png";

const LogoBox = () => {
    return (
        <div className="loginpagelogobox">
            <img className="loginpagelogo" src={Logo_Signin} alt="logo" />
            <br />
            <h2 className="loginpageservername">Task Manager</h2>
        </div>
    )
}

export default LogoBox;