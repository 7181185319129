import { Routes, Route } from "react-router-dom";
import BasePage from '../pages/base/basepage.js';
import Registration from '../pages/registration/registration.js';
import Logout from '../scripts/logout.js';
import ProtectedRoutes from "../scripts/protectedroutes.js";

const MainRoutes = (props) => {

    return (
        <Routes>
            <Route path="/*" element={
                <ProtectedRoutes auth={props.sessioninfo.userAutorised}>
                    <BasePage sessioninfo={props.sessioninfo} />
                </ProtectedRoutes>
            } />
            <Route path="/login" element={<Registration sessioninfo={props.sessioninfo} />} />
            <Route path="/logout" element={<Logout sessioninfo={props.sessioninfo} />} />
        </Routes>
    )
}

export default MainRoutes;