export async function checkUserRegistration(userName, userPass) {

    let Users = (await import("./users.js")).default;

    let users = new Users();

    return await users.checkRegistration(userName, userPass);
}

export async function isUserPresent(userName, userEmail, userPhone) {

    let Users = (await import("./users.js")).default;

    let users = new Users();

    return await users.isPresent(userName, userEmail, userPhone);
}

export async function setUserInfo(data) {

    let Users = (await import("./users.js")).default;

    let users = new Users();

    return await users.check(data.newuserloginname, data.newuserloginname);
}