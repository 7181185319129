export default class Menu{   

    parserFromGoogleDocs(t){
        const rawData = t.split('\r\n').map(line => line.split('\t'));
        const headers = rawData.shift();
        const data  = rawData.map(arr => arr.reduce((acc, el, i) => ({
            ...acc,
            [headers[i]]: el
        }), {}));
        return data;
    }

    getDataFromGoogleDocs(){
        const DB = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSTTGO4D6XJ7HEmzKgxv46gLSTtsT9pRKaQB01GreLJVqc5IYgycthmu27d3_8XgJqj9ThtH1uLUEjX/pub?gid=448472362&single=true&output=tsv';

        return  fetch(DB).then(r => r.text()).then(this.parserFromGoogleDocs);
    }

    async getMenuStructure(login, pass){
        const data = await this.getDataFromGoogleDocs();
        return data;
    }
}

