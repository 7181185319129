import './loginfooter.css';

const LoginFooter = (props) => {

    function signUp() {
        props.setPage('signup');
    }

    return (
        <footer className="loginpagecontactinfofooter darkblue">
            <button className="loginpagecontactmargin loginpagecontacttext darkgray" onClick={signUp}>Sign Up</button>
            <br />
            <div className="loginpagecontactmargin1 loginpagecontacttext1 "><a className="darkgray" href="../pages/contactpage.html">Contact Administrator</a></div>
        </footer>
    )
}

export default LoginFooter;