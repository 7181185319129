import React from 'react';

import LogoBox from './logobox.js';
import LoginForm from './loginform.js';
import LoginFooter from './loginfooter.js';
import LoginMain from './loginmain.js';
import SignUP from './signup.js';
import AllFooter from '../allfooter.js';

import './registration.css';

const Registration = (props) => {

console.log(props.sessioninfo);

    let [page, setPage] = React.useState('signin');

    return (
        <div className="loginpageregisterrow">
            <aside className="loginpagecol-6 darkblue">
                <LogoBox />
                <LoginForm setPage={props.setPage} sessioninfo={props.sessioninfo} />
                <LoginFooter setPage={setPage} />
            </aside>
            <main className="loginpagecol lightyellow">
                {
                    page === 'signin'
                        ? <LoginMain />
                        : <SignUP setPage={setPage} sessioninfo={props.sessioninfo} />
                }
                <AllFooter />
            </main>
        </div>
    )
}

export default Registration;