const AllFooter = () => {
    return (
        <footer className="allpagecopyright lightgray">
            Copyright <span>&copy;</span>Task Manager<span>&trade;</span>
            <br />
            <a href="../pages/contactus.html">Contact Us</a>
        </footer>
    )
}

export default AllFooter;