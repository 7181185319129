export default class SessionInfo {

  #userName;
  #userPass;
  #remembermy;
  #siteStorage;
  #autorised;

  constructor() {
    this.#userName = 'Ivanov';
    this.#userPass = 'Asdfgh';
    this.#remembermy = false;
    this.#autorised = false;
    this.#siteStorage = window.localStorage;
    this.menuItems = [];
    this.menuLoaded = false;
  }

  get loginName() {
    return this.#userName;
  }

  set loginName(temp = '') {
    this.#userName = temp;
  }

  get loginPass() {
    return this.#userPass;
  }

  set loginPass(temp = '') {
    this.#userPass = temp;
  }

  get loginRemember() {
    return this.#remembermy;
  }

  set loginRemember(temp = false) {
    this.#remembermy = temp;
  }

  get storage() {
    return this.#siteStorage;
  }

  get userAutorised() {
    return this.#autorised;
  }

  set userAutorised(temp = false) {
    this.#autorised = temp;
  }

}