import { Routes, Route } from "react-router-dom";
import CentralBox from "../pages/base/centralbox.js";
import Dictionary1 from '../pages/dictionary1/dictionary1.js';
import Dictionary2 from '../pages/dictionary2/dictionary2.js';
import BaseUsers from '../pages/baseusers/baseusers.js';
import Settings from '../pages/settings/settings.js';
import Tasks from '../pages/tasks/tasks.js';
import InfoPage from '../pages/settings/settings.js';

const BaseRoutes = (props) => {

    return (
        <Routes>
            <Route key="0" path="/*" element={<CentralBox sessioninfo={props.sessioninfo} />}/>
            {props.sessioninfo.menuItems.filter(el => el.form === 'Dictionary1').map(el => (
                <Route key={el.id} path={"/"+el.link} element={<Dictionary1 obj={el.link} sessioninfo={props.sessioninfo} />}/>
            ))}
            {props.sessioninfo.menuItems.filter(el => el.form === 'Dictionary2').map(el => (
                <Route key={el.id} path={"/"+el.link} element={<Dictionary2 obj={el.link} sessioninfo={props.sessioninfo} />}/>
            ))}
            {props.sessioninfo.menuItems.filter(el => el.form === 'BaseUsers').map(el => (
                <Route key={el.id} path={"/"+el.link} element={<BaseUsers obj={el.link} sessioninfo={props.sessioninfo} />}/>
            ))}
            {props.sessioninfo.menuItems.filter(el => el.form === 'Settings').map(el => (
                <Route key={el.id} path={"/"+el.link} element={<Settings obj={el.link} sessioninfo={props.sessioninfo} />}/>
            ))}
            {props.sessioninfo.menuItems.filter(el => el.form === 'Tasks').map(el => (
                <Route key={el.id} path={"/"+el.link} element={<Tasks obj={el.link} sessioninfo={props.sessioninfo} />}/>
            ))}
            {props.sessioninfo.menuItems.filter(el => el.form === 'InfoPage').map(el => (
                <Route key={el.id} path={"/"+el.link} element={<InfoPage obj={el.link} sessioninfo={props.sessioninfo} />}/>
            ))}
        </Routes>
    )
}

export default BaseRoutes;