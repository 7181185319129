

export const loaderOn = () => {
    const loader = document.getElementById('App-loader');
    if (loader != null) {
    loader.style.display = 'flex';}
}

export const loaderOff = () => {
    const loader = document.getElementById('App-loader');
    if (loader != null) {
    loader.style.display = 'none';}
}

