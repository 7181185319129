import React from 'react'
import './App.css';
import SessionInfo from './scripts/sessioninfo.js';

import Loader from './components/loader';

import MainRoutes from './components/mainroutes.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const loginDuration = 1000 * 3600 * 24 * 14;

export default function App() {

  let si = new SessionInfo();
  const tempLoginName = si.storage.getItem('loginName');    
  if (si.loginName !== '') {
    si.loginName = tempLoginName;
    const loginDate = si.storage.getItem('loginDate');  
    if (Date.now() - loginDate <= loginDuration) {
      si.loginRemember = true;
      si.userAutorised = true;    
    }    
  }

  return (
    <div className='App'>
      <Loader sessioninfo={si} />
      <MainRoutes sessioninfo={si}/>      
    </div>
  )
}